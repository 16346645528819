<template>
  <div>
    <h2>Auftragsbestätigung</h2>

    <auftrag :auftragsnr="$route.params.id" />
  </div>
</template>

<script>
import Auftrag from "../../components/Auftrag.vue";

export default {
  name: "AuftragDetailAnsicht",
  components: {
    Auftrag
  }
};
</script>
